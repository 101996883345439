import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{key:_vm.keyID,class:{
    'elevation-12': _vm.$vuetify.breakpoint.smAndUp,
    'elevation-0': _vm.$vuetify.breakpoint.xsOnly
  },attrs:{"id":"content","tile":_vm.$vuetify.breakpoint.xsOnly}},[(_vm.content.heading)?_c(VCardTitle,{staticClass:"text-h4 font-weight-thin text-wrap text-break"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pr-2",attrs:{"cols":"11"}},[_vm._v(_vm._s(_vm.$t(_vm.content.heading)))]),_c(VSpacer),_c(VCol,{staticStyle:{"text-align":"right","vertical-align":"top"},attrs:{"cols":"1"}},[(_vm.$route.meta.showModal === true)?_c(VBtn,{attrs:{"fab":"","x-small":"","elevation":"4","color":"primary","ripple":""},on:{"click":function($event){_vm.$router.go(-1);
            _vm.$store.commit('global_validateStatus', null);
            _vm.$store.commit('global_clearError');}}},[_c(VIcon,[_vm._v("$mdiClose")])],1):_vm._e()],1)],1)],1):_vm._e(),(_vm.content.name)?_c(VCardSubtitle,{staticClass:"pl-4 mt-0"},[_c('strong',[_vm._v(_vm._s(_vm.$t(_vm.content.name))+": ")]),_vm._v(_vm._s(this.$store.getters["company/get_locationName"])+" ")]):_vm._e(),(_vm.content.bodytext)?_c(VCardText,{staticClass:"v-input",attrs:{"id":"oben"},domProps:{"textContent":_vm._s(_vm.$t(_vm.content.bodytext))}}):_vm._e(),(_vm.content.subheading)?_c(VCardSubtitle,{staticClass:"mb-2",domProps:{"textContent":_vm._s(_vm.$t(_vm.content.subheading))}}):_vm._e(),_c(VCardText,[_c(VForm,{attrs:{"id":"submitForm","lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('form-builder',_vm._g({key:_vm.keyID,ref:"dynFormBuilder",attrs:{"schema":_vm.schema,"editable":_vm.editable},on:{"status":_vm.saveStatus},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}},_vm.$listeners))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }