<template>
  <v-card
    :key="keyID"
    id="content"
    :class="{
      'elevation-12': $vuetify.breakpoint.smAndUp,
      'elevation-0': $vuetify.breakpoint.xsOnly
    }"
    :tile="$vuetify.breakpoint.xsOnly"
  >
    <!--    'pb-1': get_global_error,-->
    <v-card-title
      v-if="content.heading"
      class="text-h4 font-weight-thin text-wrap text-break"
    >
      <v-row no-gutters>
        <v-col cols="11" class="pr-2">{{ $t(content.heading) }}</v-col>
        <v-spacer />
        <v-col cols="1" style="text-align: right; vertical-align: top ">
          <v-btn
            fab
            x-small
            elevation="4"
            color="primary"
            ripple
            v-if="$route.meta.showModal === true"
            @click="
              $router.go(-1);
              $store.commit('global_validateStatus', null);
              $store.commit('global_clearError');
            "
          >
            <v-icon>$mdiClose</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle v-if="content.name" class="pl-4 mt-0"
      ><strong>{{ $t(content.name) }}: </strong
      >{{ this.$store.getters["company/get_locationName"] }}
    </v-card-subtitle>
    <v-card-text
      v-if="content.bodytext"
      v-text="$t(content.bodytext)"
      class="v-input"
      id="oben"
    />
    <v-card-subtitle
      v-if="content.subheading"
      v-text="$t(content.subheading)"
      class="mb-2"
    />
    <v-card-text>
      <v-form @submit.prevent="submitForm" id="submitForm" lazy-validation>
        <form-builder
          v-bind:schema="schema"
          v-model="formData"
          v-on="$listeners"
          @status="saveStatus"
          ref="dynFormBuilder"
          :key="keyID"
          :editable="editable"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable no-console */
import FormBuilder from "./forms/FormBuilder";
import { mapActions } from "vuex";
import { hasKeys } from "../helpers/helpers";

export default {
  name: "Card",
  components: { FormBuilder },
  props: ["content", "formData", "schema", "editable"],
  data() {
    return {
      loader: null,
      loading: false,
      status: null,
      error: false,
      submitted: false,
      errors: [],
      finalErrorStatus: false,
      companyRequiredFields: [
        "billingAddressChoose",
        "caBillingEmail",
        "caCountry",
        "caLocation",
        "caPostalCode",
        "caStreetNumber",
        "caVAT",
        "cpEmail",
        "cpFirstName",
        "cpLastName",
        "cpMobile",
        "cpPhone"
      ]
    };
  },
  computed: {
    keyID() {
      return this.$router.currentRoute.path.replace(/\//g, "_");
    } //dynamic :key
  },
  methods: {
    /**
     * Async form-submit
     * */
    async submitForm() {
      this.submitted = true;
      /**
       * Submit and validate module data; exclude send-module.
       * Uses dynamic schema-based validation.
       * */
      if (this.$route.name !== "send") {
        this.$store.commit("global_clearError");
        this.$emit("validateNow");
        this.$store.commit("global_validateStatus", "validateNow");
        this.$refs.dynFormBuilder.validateForm();
        if (this.$route.name === "layingfarm") {
          let data = this.$store.getters["layingFarm/getDataLayingFarm"];
          let errors = 0;
          if (data.lfStables.length > 0) {
            data.lfStables.forEach(stable => {
              if (
                stable.stableID !== undefined &&
                stable.lfStableName === undefined
              ) {
                ++errors;
              }
            });
          }
          if (errors > 0) {
            this.$store.commit("global_errorStatus", true);
            this.$store.commit(
              "global_error",
              { message: "validationMessages.layingMissingStableName" },
              { root: true }
            );
            return;
          }
        }
        if (this.status.invalid) {
          this.$store.commit("global_errorStatus", true);
          this.$store.commit(
            "global_error",
            { message: "validationMessages.requiredInfo" },
            { root: true }
          );
          return;
        } else {
          this.$store.commit("global_validateStatus", null, { root: true });
          this.$store.commit("global_clearError");
          this.$emit("submitAction");
        }
      }
      /**
       * Final submit and validation.
       * Uses custom validation.
       * */
      if (this.$route.name === "send") {
        this.$store.commit("global_clearError");
        this.$store.commit("validator/clear_ValidatorErrors");
        await this.filteredLocations();
        if (this.finalErrorStatus === true) {
          this.$store.commit("global_errorStatus", true);
          this.$store.commit(
            "global_error",
            { message: [...this.errors] },
            { root: true }
          );
          return;
        } else {
          this.$store.commit("global_validateStatus", null, { root: true });
          this.$store.commit("global_clearError");
          this.$emit("submitAction");
        }
      }
    },
    saveStatus(status) {
      this.status = status;
    },

    ...mapActions({
      preloadLocations: "company/loadCompanymoduleData",
      preloadPreraiser: "preraiser/loadPreModuleData",
      preloadRearer: "rearer/loadRmoduleData",
      preloadLayingFarm: "layingFarm/loadLFmoduleData",
      preloadRequestType: "requestType/loadRequestTypeModuleData",
      validateAll: "validator/loadModuleSchemaAndData"
    }),

    /**
     * Async function stack for final custom validation.
     * Checks whether the applicant has at least one facility
     * and if he had chosen layingfarm or rearer he must have
     * at least on stable per each. Loops over locations-array and
     * collects all rearer- and layingfarm-facilities in arrays
     * which will be asynchronously looped. Collects all errors in array.
     * */
    async filteredLocations() {
      const loc32 = []; //preraiser
      const loc1 = []; //rearer
      const loc15 = []; //layingfarm

      try {
        await this.getRequestType(); //check if requestType is filled

        //load locations and check minimum
        const locs = await this.getLocations();

        //process and push locations per production type
        locs.locations.filter(function(loc) {
          if (loc.productionType.itemValue === 32) {
            loc32.push([
              loc.facilityName,
              loc.locationID,
              loc.productionType.itemValue
            ]);
          } else if (loc.productionType.itemValue === 1) {
            loc1.push([
              loc.facilityName,
              loc.locationID,
              loc.productionType.itemValue
            ]);
          } else if (loc.productionType.itemValue === 15) {
            loc15.push([
              loc.facilityName,
              loc.locationID,
              loc.productionType.itemValue
            ]);
          }
        });

        //check all preraisers
        if (loc32.length > 0) {
          for (const preraiser of loc32) {
            await this.getPreraiser(preraiser);
          }
        }

        //check all rearer
        if (loc1.length > 0) {
          for (const rearer of loc1) {
            await this.getRearer(rearer);
          }
        }

        //check all layingFarms
        if (loc15.length > 0) {
          for (const lf of loc15) {
            await this.getLayingFarm(lf);
          }
        }

        //check all other facilities and errors
        await this.allLocations(locs.locations);
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * Async load company locations and process error-messages
     * in translatable manner.
     * */
    async getLocations() {
      const contents = await this.preloadLocations();
      if (
        contents.locations.length < 1 ||
        !hasKeys(contents, this.companyRequiredFields)
      ) {
        this.finalErrorStatus = true; //this is an important bit of information
        if (contents.locations.length < 1) {
          this.errors.push("{{facilityMinimum}}");
          this.errors = [...new Set(this.errors)];
        }
        if (!hasKeys(contents, this.companyRequiredFields)) {
          this.errors.push("{{companyDetails}}");
          this.errors = [...new Set(this.errors)];
        }
        return contents;
      } else return contents;
    },

    /**
     * Async processing of all locations retrieved by getLocations -> preloadLocations.
     * This is done in store module validator. Each location moduleData is fetched
     * and checked and validated. Error messages are processed. (See Readme for
     * further details)
     * */
    async allLocations(locations) {
      const all = await this.validateAll(locations);
      if (all.length > 0) {
        this.finalErrorStatus = true;
        this.errors.push(...all);
        this.errors = [...new Set(this.errors)];
      }
    },

    /**
     * Async load preraiser stables and process error-messages
     * in translatable manner per preraising facility.
     * */
    async getPreraiser(item) {
      const contents = await this.preloadPreraiser(item[1]);
      if (contents === undefined) return;
      if (contents.preStables.length < 1) {
        this.finalErrorStatus = true;
        this.errors.push(item[0] + " => " + "{{preraiserStablesMinimum}}");
        this.errors = [...new Set(this.errors)];
      }
    },

    /**
     * Async load rearer stables and process error-messages
     * in translatable manner per rearer facility.
     * */
    async getRearer(item) {
      const contents = await this.preloadRearer(item[1]);
      if (contents === undefined) return;
      if (contents.rStables.length < 1) {
        this.finalErrorStatus = true;
        this.errors.push(item[0] + " => " + "{{rearerStablesMinimum}}");
        this.errors = [...new Set(this.errors)];
      }
    },

    /**
     * Async load layingfarm stables and process error-messages
     * in translatable manner per layingFarm facility.
     * */
    async getLayingFarm(item) {
      const contents = await this.preloadLayingFarm(item[1]);
      if (contents === undefined) return;
      if (contents.lfStables.length < 1) {
        this.finalErrorStatus = true;
        this.errors.push(item[0] + " => " + "{{layingFarmStablesMinimum}}");
        this.errors = [...new Set(this.errors)];
      }
    },

    /**
     * Async load requestType module-data status. Check whether data exists or not.
     * Process error-message in translatable manner.
     * */
    async getRequestType() {
      const contents = await this.preloadRequestType();
      if (contents === undefined) return;
      if (!contents) {
        this.finalErrorStatus = true;
        this.errors.push("{{requestTypeRequired}}");
      }
    }
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 500);
      this.loader = null;
    }
  }
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__title {
  padding: 16px;
}
</style>
